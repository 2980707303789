/* .form{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.input{
    width :100%;
    padding: 10px;
    padding-top: 15px;
}

.select{
    padding: 10px;
    margin-top: 15px;
    
} */
 /* General Form Styling */
.form {
    padding: 20px; /* Padding around the form */
    border-radius: 10px; /* Rounded corners */
    max-width: 400px; /* Set a max-width for the form */
    margin: auto; /* Center the form */
    font-family: 'Arial', sans-serif; /* Consistent font */
}

/* Form Title Styling */
.form h3 {
    text-align: center; /* Center the title */
    font-size: 1.5em; /* Larger font size */
    color: #333; /* Darker color for contrast */
    margin-bottom: 20px; /* Space below the title */
}

/* Input Field Styling */
.form .input {
    width: calc(100% - 20px); /* Full width with padding adjustment */
    padding: 10px; /* Padding inside the input */
    margin-bottom: 15px; /* Space below each input */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid var(--tg-theme-button-color);; /* Light border */
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); /* Inner shadow */
    font-size: 1em; /* Font size */
}

/* Dropdown Styling */
.form .select {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the select */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid var(--tg-theme-button-color);; /* Light border */
    margin-bottom: 20px; /* Space below the select */
    font-size: 1em; /* Font size */
}

/* Button Styling */
.form button {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the button */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    background-color: var(--tg-theme-button-color);; /* Blue background */
    color: white; /* White text */
    font-size: 1.1em; /* Slightly larger font */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth transition for hover */
}

/* Close Button Styling */
.form .close-form-btn {
    background-color: var(--tg-theme-button-color);; /* Red background */
    color: white; /* White text */
    padding: 8px 10px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    width: 100%; /* Full width */
    margin-top: 10px; /* Space above the button */
    transition: background-color 0.3s; /* Smooth transition for hover */
}
