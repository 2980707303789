/* .button{
    margin: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    justify-content: center;
} */
.button {
    margin-top: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px; 
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

