/* 
body{
    font-family: Arial, sans-serif;
} */
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.product{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--tg-theme-button-color);
    border-radius: 15px;
    padding: 15px;
    width: 170px;
}

.img{
    width: 100%;
    height: 100px;
    margin: 0 auto;
}

.description{
    font-size: 12px;
}

.add-btn{
    width: 100%;
    justify-content: center;
}


.product .img img {
    width: 100%;
    height: auto; 
    object-fit: cover;
}

.img{
    margin-bottom: 36px;
}

  
.title{
    font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal;
    font-size: 18px;
}

.price{
    margin-top: 4px;
    font-size: 18px;
}