/* body {
    font-family: 'Montserrat', sans-serif;
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&family=Raleway:wght@600;700&display=swap');

/* 
.congratulations-container {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.congratulations-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
}

.congratulations-container p {
    font-size: 18px;
    color: #333;
    font-weight: 200;
} */





/* body {
    font-family: 'Poppins', sans-serif;
    color: #333;
} */

.congratulations-container {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.congratulations-container h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #333;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.congratulations-container p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #555; 
    margin-top: 5px;
}

.tu-gif {
    width: 100%;
    max-width: 400px; 
    height: auto;
    margin-top: 20px; 
    display: block;
    margin-left: auto;
    margin-right: auto;
}
