/* cart.css */
.cart {
    position:fixed;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background-color: var(--tg-theme-background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cart h2 {
    margin-top: 0;
}

.cart ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
    width: 80%;
    max-width: 500px;
}

.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cart-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.pay-btn, .close-btn {
    margin-top: 10px;
    padding: 10px;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    max-width: 500px;
}


.cart-btn {
    margin: 10px 0;
    padding: 10px;
    background-color: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    max-width: 500px;
}

.trash{
    color: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}