@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* body{
    font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px; */
  /* font-family: "Montserrat Alternates", sans-serif;
  font-weight: 400;
  font-style: normal
} */

.header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
}

.username{
    margin-left: 4px;
    margin-top: 4px;
    font-family: "Nunito", sans-serif;
}