/* body {
    font-family: Arial, sans-serif;
    background-color: var(--tg-theme-background-color);
    margin: 0;
    padding: 0;
}

.container {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.filter {
    margin-bottom: 20px;
}

.filter label {
    margin-right: 10px;
}

.filter select {
    padding: 5px;
    font-size: 16px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.product-item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 15px;
    width: calc(50% - 20px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}


.product-item h2 {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-item p {
    font-size: 1em;
    margin: 10px 0;
    color: #666;
}

.product-item button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background:  var(--tg-theme-button-color);
    color: #fff;
    cursor: pointer;
}

.cart-btn-container {
    text-align: center;
    margin-bottom: 20px;
}

.cart-btn {
    display: inline-block;
    padding: 10px 20px;
    background:  var(--tg-theme-button-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-align: center;
}


.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--tg-theme-button-color);
    color: white;
    border: none;
    padding: 8px 16px 12px 16px;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
}


.close-form-btn {
    color: white; 
    padding: 8px 10px; 
    border-radius: 5px; 
    border: none;
    cursor: pointer; 
    width: 100%; 
    margin-top: 10px; 
    transition: background-color 0.3s;
}
 */
 @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

 .product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items */
    gap: 20px;
}

.product-item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 15px;
    width: calc(50% - 20px); /* 2 items per row with a 20px gap */
    max-width: 250px; /* Prevents items from getting too wide */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--tg-theme-button-color);
    color: white;
    border: none;
    padding: 8px 16px 12px 16px;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
}

.toph {
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    align-items: center;
}


.filter {
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;
}

.filter label {
    margin-right: 10px;
}

.filter select {
    padding: 5px;
    font-size: 16px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}


@media (max-width: 768px) {
    .product-item {
        width: calc(50% - 20px); /* 2 items per row on tablets and larger phones */
    }
}

@media (max-width: 480px) {
    .product-item {
        width: calc(100% - 20px); /* 1 item per row on very small screens */
    }
}
